/* eslint-disable max-len */
import Vue from 'vue';
import Router from 'vue-router';

/* Layout */
import Layout from '../views/layout/Layout';

Vue.use(Router);

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 * */

export const asyncRouterMap = [{
  path: '/productService',
  component: Layout,
  redirect: '/productService/product',
  name: 'productService',
  meta: { title: 'Product', icon: 'product', roles: ['admin', 'marketing'] },
  children: [{
    path: 'product',
    name: 'product',
    component: () => import('@/views/productService/product/index'),
    meta: { title: 'Products', icon: 'product-list', keepAlive: true },
  },
  {
    path: 'addProduct',
    name: 'addProduct',
    component: () => import('@/views/productService/product/components/AddProduct'),
    meta: { title: 'Add Products', icon: 'product-add' },
  },
  {
    path: 'updateProduct',
    name: 'updateProduct',
    component: () => import('@/views/productService/product/components/UpdateProduct'),
    meta: { title: 'Update Products', icon: 'product-add' },
    hidden: true,
  },
  {
    path: 'productCate',
    name: 'productCate',
    component: () => import('@/views/productService/productCate/index'),
    meta: { title: 'Product Categories', icon: 'product-cate' },
  },
  {
    path: 'addProductCate',
    name: 'addProductCate',
    component: () => import('@/views/productService/productCate/add'),
    meta: { title: 'Add Product Category' },
    hidden: true,
  },
  {
    path: 'updateProductCate',
    name: 'updateProductCate',
    component: () => import('@/views/productService/productCate/update'),
    meta: { title: 'Update Product Category' },
    hidden: true,
  },
  {
    path: 'productAttr',
    name: 'productAttr',
    component: () => import('@/views/productService/productAttr/index'),
    meta: { title: 'Product Attr Categories', icon: 'product-attr' },
  },
  {
    path: 'productAttrList',
    name: 'productAttrList',
    component: () => import('@/views/productService/productAttr/productAttrList'),
    meta: { title: 'Product Attribute List' },
    hidden: true,
  },
  {
    path: 'addProductAttr',
    name: 'addProductAttr',
    component: () => import('@/views/productService/productAttr/addProductAttr'),
    meta: { title: 'Add Product Attribute' },
    hidden: true,
  },
  {
    path: 'updateProductAttr',
    name: 'updateProductAttr',
    component: () => import('@/views/productService/productAttr/updateProductAttr'),
    meta: { title: 'Update Product Attribute' },
    hidden: true,
  },
  ],
},
{
  path: '/banner',
  component: Layout,
  redirect: '/banner/index',
  name: 'banner',
  meta: { title: 'Banner', icon: 'banner', roles: ['admin', 'marketing'] },
  children: [{
    path: 'index',
    name: 'bannerIndex',
    component: () => import('@/views/banner/index'),
    meta: { title: 'Banner', icon: 'banner' },
  }],
},
{
  path: '/digitalProduct',
  component: Layout,
  redirect: '/digitalProduct/index',
  name: 'digitalProduct',
  meta: { title: 'Digital Product', icon: 'product', roles: ['admin', 'marketing'] },
  children: [{
    path: 'index',
    name: 'index',
    component: () => import('@/views/productService/digitalProduct/index'),
    meta: { title: 'Digital Product', icon: 'digital-product' },
  },
  {
    path: 'addproduct',
    name: 'addproduct',
    component: () => import('@/views/productService/digitalProduct/components/AddProduct'),
    meta: { title: 'Add Digital Product', icon: 'digital-product' },
  },
  {
    path: 'updateProduct',
    name: 'updateDigitalProduct',
    component: () => import('@/views/productService/digitalProduct/components/UpdateProduct'),
    meta: { title: 'Update Digital Products', icon: 'product-add' },
    hidden: true,
  },
  ],
},
{
  path: '/merchantService',
  component: Layout,
  redirect: '/merchantService/merchant',
  name: 'merchantService',
  meta: { title: 'Merchant', icon: 'merchant', roles: ['admin', 'marketing'] },
  children: [{
    path: 'merchant',
    name: 'merchant',
    component: () => import('@/views/merchantService/merchant/index'),
    meta: { title: 'Merchants', icon: 'merchant-list' },
  },
  {
    path: 'merchantArea',
    name: 'merchantArea',
    component: () => import('@/views/merchantService/merchant/area'),
    meta: { title: 'Merchant Areas', icon: 'merchant-area' },
  },
  {
    path: 'addMerchant',
    name: 'addMerchant',
    component: () => import('@/views/merchantService/merchant/AddMerchant'),
    meta: { title: 'Add Merchant', icon: 'merchant-add' },
    hidden: true,
  },
  {
    path: 'updateMerchant',
    name: 'updateMerchant',
    component: () => import('@/views/merchantService/merchant/UpdateMerchant'),
    meta: { title: 'Update Merchant', icon: 'merchant-update' },
    hidden: true,
  },
  {
    path: 'merchantMenu',
    name: 'merchantMenu',
    component: () => import('@/views/merchantService/merchant/menu'),
    meta: { title: 'Merchant Menus', icon: 'merchant-menu' },
    hidden: true,
  },
  ],
},
{
  path: '/orderService',
  component: Layout,
  redirect: '/orderService/orders',
  name: 'orderService',
  meta: { title: 'Orders', icon: 'order', roles: ['admin', 'marketing'] },
  children: [{
    path: 'orders',
    name: 'orders',
    component: () => import('@/views/orderService/order/index'),
    meta: {
      title: 'Orders',
      icon: 'order-list',
      roles: ['admin', 'marketing'],
    },
  },
  {
    path: 'digitalOrder',
    name: 'digitalOrder',
    component: () => import('@/views/orderService/order/digitalOrder'),
    meta: {
      title: 'Digital Orders',
      icon: 'order-list',
      roles: ['admin', 'marketing'],
    },
  },
  {
    path: 'refund',
    name: 'refund',
    component: () => import('@/views/orderService/refund/index'),
    meta: { title: 'Refund Tickets', roles: ['admin'] },
  },
  {
    path: 'reconcile',
    name: 'reconcile',
    component: () => import('@/views/orderService/reconcile'),
    meta: { title: 'Reconcile', roles: ['admin'] },
  }, {
    path: 'orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/orderService/order/orderDetail'),
    meta: {
      title: 'Order Details',
      icon: 'order-detail',
      roles: ['admin', 'marketing'],
    },
    hidden: true,
  },
  ],
},
{
  path: '/receipt',
  name: '/receipt',
  component: () => import('@/views/orderService/order/receipt'),
  meta: { roles: ['admin'] },
  hidden: true,
}, {
  path: '/captainService',
  component: Layout,
  redirect: '/captainService/index',
  name: 'captainService',
  meta: { title: 'Captain', icon: 'captain', roles: ['admin'] },
  children: [{
    path: 'index',
    name: 'captainIndex',
    component: () => import('@/views/captainService/index'),
    meta: { title: 'Captains', icon: 'captain-list' },
  }],
}, {
  path: '/bulkService',
  component: Layout,
  redirect: '/bulkService/bulk',
  name: 'bulkService',
  meta: { title: 'Bulk', icon: 'bulk', roles: ['admin'] },
  children: [{
    path: 'ongoingProduct',
    name: 'ongoingProduct',
    component: () => import('@/views/bulkService/bulk/OngoingProduct'),
    meta: { title: 'Ongoing Products', icon: 'bulks' },
  },
  {
    path: 'ongoingBulk',
    name: 'ongoingBulk',
    component: () => import('@/views/bulkService/bulk/OngoingBulk'),
    meta: { title: 'Ongoing Bulks', icon: 'bulks' },
    hidden: true,
  },
  {
    path: 'ongoingOrder',
    name: 'ongoingOrder',
    component: () => import('@/views/bulkService/bulk/OngoingOrder'),
    meta: { title: 'Ongoing Order', icon: 'bulks' },
    hidden: true,
  },
  {
    path: 'bulk',
    name: 'bulk',
    component: () => import('@/views/bulkService/bulk/index'),
    meta: { title: 'Bulks', icon: 'bulks' },
  },
  {
    path: 'createBulk',
    name: 'createBulk',
    component: () => import('@/views/bulkService/bulk/CreateBulk'),
    meta: { title: 'Create Bulk', icon: 'create-Bulk' },
    hidden: true,
  },
  {
    path: 'editBulk',
    name: 'editBulk',
    component: () => import('@/views/bulkService/bulk/EditBulk'),
    meta: { title: 'Edit Bulk', icon: 'edit-Bulk' },
    hidden: true,
  },
  ],
}, {
  path: '/consumerService',
  component: Layout,
  name: 'consumerSerivce',
  meta: { title: 'Consumer', icon: 'consumer', roles: ['admin'] },
  children: [{
    path: 'consumer',
    name: 'consumer',
    component: () => import('@/views/consumerService/consumer/index'),
    meta: { title: 'All Consumers', icon: 'consumer' },
  },
  {
    path: 'sendNotification',
    name: 'sendNotification',
    component: () => import('@/views/consumerService/consumer/SendNotification'),
    meta: { title: 'Send Notification', icon: 'consumer' },
  },
  {
    path: 'helpCenter',
    name: 'helpCenter',
    component: () => import('@/views/consumerService/helpCenter/index'),
    meta: { title: 'Help Center', icon: 'consumer' },
  },
  ],
}, {
  path: '/adminConsumer',
  component: Layout,
  name: 'adminConsumer',
  meta: { title: 'Admin Consumer', icon: 'consumer', roles: ['admin'] },
  children: [{
    path: 'adminConsumer',
    name: 'adminConsumerIndex',
    component: () => import('@/views/adminConsumer/index'),
    meta: { title: 'Admin Consumer List' },
  },
  {
    path: 'token',
    name: 'token',
    component: () => import('@/views/adminConsumer/Token'),
    meta: { title: 'Consumer Login' },
  },
  ],
}, {
  path: '/redis',
  component: Layout,
  name: 'redis',
  meta: { title: 'Redis', roles: ['admin'] },
  children: [{
    path: 'redis',
    name: 'redisIndex',
    component: () => import('@/views/redis/index'),
    meta: { title: 'redis' },
  }],
}, {
  path: '/promo',
  component: Layout,
  name: 'promo',
  meta: { title: 'Promo', roles: ['admin'] },
  children: [{
    path: 'addPromo',
    name: 'addPromo',
    component: () => import('@/views/promo/AddPromo'),
    meta: { title: 'Add Promo' },
  }],
}, {
  path: '/badge',
  component: Layout,
  name: 'bagde',
  meta: { title: 'Badge', roles: ['admin'] },
  children: [{
    path: 'badge',
    name: 'badgeIndex',
    component: () => import('@/views/badge/index'),
    meta: { title: 'Badge Tasks' },
  }, {
    path: 'createBadge',
    name: 'createBadge',
    component: () => import('@/views/badge/CreateBadgeTask'),
    meta: { title: 'Create Badge' },
  },
  {
    path: 'editBadge',
    name: 'editBadge',
    component: () => import('@/views/badge/EditBadgeTask'),
    meta: { title: 'Edit Badge' },
    hidden: true,
  },
  ],
}, {
  path: '/membership',
  component: Layout,
  name: 'membership',
  meta: { title: 'Membership', roles: ['admin', 'marketing'] },
  children: [{
    path: 'membership',
    name: 'membershipIndex',
    component: () => import('@/views/membership/index'),
    meta: { title: 'Membership' },
  }, {
    path: 'createMembership',
    name: 'createMembership',
    component: () => import('@/views/membership/create'),
    meta: { title: 'Create Membership' },
  }, {
    path: 'imageOption',
    name: 'imageOption',
    component: () => import('@/views/imageOption/add'),
    meta: { title: 'Add Image Option' },
  }, {
    path: 'updateMembership',
    name: 'updateMembership',
    component: () => import('@/views/membership/update'),
    meta: { title: 'Update Membership' },
    hidden: true,
  }],

}, {
  path: '/version',
  component: Layout,
  name: 'version',
  meta: { title: 'Version', roles: ['admin'] },
  children: [{
    path: 'version',
    name: 'versionIndex',
    component: () => import('@/views/version/index'),
    meta: { title: 'All Versions' },
  }, {
    path: 'createVersion',
    name: 'createVersion',
    component: () => import('@/views/version/CreateVersion'),
    meta: { title: 'Create Version' },
  },
  {
    path: 'editVersion',
    name: 'editVersion',
    component: () => import('@/views/version/EditVersion'),
    meta: { title: 'Edit Version' },
    hidden: true,
  },
  ],
}, {
  path: '/androidVersion',
  component: Layout,
  name: 'androidVersion',
  meta: { title: 'Android Version', roles: ['admin'] },
  children: [{
    path: 'version',
    name: 'versionIndex',
    component: () => import('@/views/androidVersion/index'),
    meta: { title: 'All Versions' },
  }, {
    path: 'createVersion',
    name: 'createVersion',
    component: () => import('@/views/androidVersion/CreateVersion'),
    meta: { title: 'Create Version' },
  },
  {
    path: 'editVersion',
    name: 'editVersion',
    component: () => import('@/views/androidVersion/EditVersion'),
    meta: { title: 'Edit Version' },
    hidden: true,
  },
  ],
},
{
  path: '/settings',
  component: Layout,
  name: 'settings',
  meta: { title: 'Settings', roles: ['admin'] },
  children: [{
    path: 'settings',
    name: 'settingsIndex',
    component: () => import('@/views/settings/index'),
    meta: { title: 'Settings' },
  }],
}, {
  path: '/openScreen',
  component: Layout,
  name: 'OpenScreen',
  meta: { title: 'OpenScreen', roles: ['admin', 'marketing'] },
  children: [{
    path: 'openScreen',
    name: 'openScreenIndex',
    component: () => import('@/views/settings/OpenScreen'),
    meta: { title: 'OpenScreen Image' },
  }],
}, { path: '*', redirect: '/404', hidden: true },
];

export const constantRouterMap = [{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true,
},
{
  path: '/404',
  component: () => import('@/views/404'),
  hidden: true,
},
{
  path: '',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'home',
    component: () => import('@/views/home/index'),
    meta: { title: '首页', icon: 'home', roles: ['admin', 'marketing'] },
  }],
},
];

export default new Router({
  // mode: 'history'
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
