import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    role: null,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLE: (state, role) => {
      state.role = role;
    },
  },

  actions: {
    Login({ commit }, userInfo) {
      const { email, password } = userInfo;
      return new Promise((resolve, reject) => {
        login(email, password).then((response) => {
          const { data } = response;
          const tokenStr = `Bearer ${data.token}`;
          setToken(tokenStr);
          commit('SET_TOKEN', tokenStr);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then((response) => {
          const { data } = response;
          if (typeof data.role == 'string') {
            commit('SET_ROLE', data.role);
          } else {
            reject('getInfo: roles must be a non-null array !');
          }
          commit('SET_NAME', data.firstName);
          commit('SET_AVATAR', data.icon);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        removeToken();
        location.reload();
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
