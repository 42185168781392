<template>
  <section class="app-main">
    <keep-alive>
      <transition v-if="$route.meta.keepAlive" name="fade" mode="out-in">
          <router-view></router-view>
      </transition>
    </keep-alive>
    <transition v-if="!$route.meta.keepAlive" name="fade1" mode="out-in">
        <router-view></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  },
};
</script>
