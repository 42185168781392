import request from '@/utils/request';

export function login(email, password) {
  return request({
    url: 'v1/admin/login',
    method: 'post',
    data: {
      email,
      password,
    },
  });
}

export function getInfo() {
  return request({
    url: 'v1/admin/info',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post',
  });
}

export function fetchList(params) {
  return request({
    url: '/admin/list',
    method: 'get',
    params,
  });
}

export function createAdmin(data) {
  return request({
    url: '/admin/register',
    method: 'post',
    data,
  });
}

export function updateAdmin(id, data) {
  return request({
    url: `/admin/update/${id}`,
    method: 'post',
    data,
  });
}

export function updateStatus(id, params) {
  return request({
    url: `/admin/updateStatus/${id}`,
    method: 'post',
    params,
  });
}

export function deleteAdmin(id) {
  return request({
    url: `/admin/delete/${id}`,
    method: 'post',
  });
}

export function getRoleByAdmin(id) {
  return request({
    url: `/admin/role/${id}`,
    method: 'get',
  });
}

export function allocRole(data) {
  return request({
    url: '/admin/role/update',
    method: 'post',
    data,
  });
}
