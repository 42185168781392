/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { asyncRouterMap, constantRouterMap } from '@/router/index';

// 判断是否有权限访问该菜单
function hasPermission(role, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(role);
  }
  return true;
}

const GenerateRoutes = (routes, role) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(role, tmp)) {
      if (tmp.children) {
        tmp.children = GenerateRoutes(tmp.children, role);
      }
      res.push(tmp);
    }
  });
  return res;
};

const permission = {
  state: {
    routers: constantRouterMap,
    role: '',
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.routers = routers;
    },
    SET_ROLE: (state, role) => {
      state.role = role;
    },
  },
  actions: {
    getAsyncRoutes({ commit }, role) {
      const filterRoutes = GenerateRoutes(asyncRouterMap, role);
      commit('SET_ROUTERS', constantRouterMap.concat(filterRoutes));
      return filterRoutes;
    },
  },
};

export default permission;
