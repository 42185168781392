/* eslint-disable import/order */
import router from './router';
import store from './store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
import { C } from 'caniuse-lite/data/agents';

const whiteList = ['/login'];
const dispatched = false;
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' });
      // eslint-disable-next-line max-len
      NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
    } else {
      const hasRole = typeof store.state.user.role == 'string';
      if (!hasRole) {
        await store.dispatch('GetInfo');
        const role = store.state.user.role;
        const routes = await store.dispatch('getAsyncRoutes', role);
        router.addRoutes(routes);
        next({ ...to, replace: true });
      }
      next();
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    next('/login');
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});
